import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProps, Link } from "gatsby"
//TODO MIGUEL: Need to understand why PageProps is needed for Link to work, even though VSCode says it is not used
//import Link from "gatsby"

class Counter extends React.Component {
    constructor() {
        super()
        this.state = { count: 0 }
    }
    render() {
        return <Layout>
            <SEO title="Counter JS" />
            <div>
                <h1>Counter JS</h1>
                <p>Current count: {this.state.count}</p>
                <button onClick={() => this.setState({ count: this.state.count + 1 })}>plus</button>
                <button onClick={() => this.setState({ count: this.state.count - 1 })}>minus</button>
            </div>
            <Link to="/">Go to home page</Link>
        </Layout>
    }
}
export default Counter